<template>
  <div class="admin-locations">
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/settings')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings - Locations
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search locations"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadLocations"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createLocation"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- List wrapper -->
    <div class="locations-list-wrapper">
      <a-alert style="margin-bottom: 25px" message="Locations help quickly define where an Incident is happening">
      </a-alert>

      <!-- <div class="location-org-context-switcher-wrapper">
        <playbook-org-context-switcher
          @set-owner-id-filter="setOwnerIdFilter"
          :owner-id-filter="ownerIdFilter"
          :locations="locations"
        ></playbook-org-context-switcher>
      </div> -->

      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No locations -->
      <a-alert
        v-if="!isLoading && locationsToShow.length == 0"
        type="info"
        message="No locations to show"
      >
      </a-alert>
      <!-- / No locations -->

      <!-- Loaded -->
      <div class="locations-list" v-if="!isLoading && locationsToShow.length">
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="location in locationsToShow"
            :key="location.id"
          >
            <location-list-item
              :location="location"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToLocation(location)"
            ></location-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LocationListItem from "./Locations/LocationListItem.vue";
import organisations from "../../../api/organisations";
// import PlaybookOrgContextSwitcher from "../../../components/Admin/Playbooks/PlaybookOrgContextSwitcher.vue";
const _ = require("lodash");
export default {
  components: { LocationListItem },

  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: false,
      locations: [],
      searchQuery: "",
      ownerIdFilter: null,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.ownerIdFilter = this.selectedOrganisation.id;
    this.loadLocations();
  },

  watch: {
    selectedOrganisation() {
      this.loadLocations();
    },
  },

  methods: {
    setOwnerIdFilter(val) {
      this.ownerIdFilter = val;
    },

    navigateToLocation(location) {
      this.$router.push("/admin/settings/locations/" + location.id + '/edit');
    },

    createLocation() {
      this.$router.push("/admin/settings/locations/create");
    },

    loadLocations() {
      let vm = this;
      vm.isLoading = true;
      vm.locations = [];
      organisations
        .getLocationsForOrg(this.tenantId, this.selectedOrganisation.id, true)
        .then((r) => {
          vm.locations = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading locations");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    locationsToShow() {
      let filtered = _.filter(this.locations, (locations) => {
        if (this.searchQuery.trim().length) {
          return locations.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      let ordered = _.orderBy(filtered, "displayName");
      return _.filter(ordered, location => {
        return location.ownerId == this.ownerIdFilter;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.location-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.location-org-context-switcher-wrapper {
  margin-bottom: 25px;
}
</style>